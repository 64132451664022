import React, { useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

const Chapter = ({ chapter }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        className="flex justify-between items-center w-full text-left text-green-400 font-semibold p-4 bg-gray-800 rounded-lg"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{chapter.title}</span>
        {isOpen ? (
          <MdKeyboardArrowUp className="h-5 w-5" />
        ) : (
          <MdKeyboardArrowDown className="h-5 w-5" />
        )}
      </button>
      {isOpen && (
        <div className="mt-2 space-y-2">
          {chapter.lessons.map((lesson, index) => (
            <div key={index} className="flex justify-between items-center text-sm p-2">
              <span>{lesson.title}</span>
              <span className="text-gray-400">{lesson.duration}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Chapter;