import { Link } from "react-router-dom";
import imgCristian from '../assets/1578750284142.webp';

// Definir un objeto de rutas dinámicas basado en el ID de los posts
const routes = {
  2: "/faq-entreview-junior/",
  3: "/faq-javascript-semis-senior/",
  4: "/web-design-tips/",
};

// Función para obtener la ruta correcta para cada post
const getPostLink = (post) => {
  return routes[post.id] || `/blog/${post.category.title}/`;
};

const posts = [
  {
    id: 1,
    title: 'La IA se va a quedar con todo el empleo?',
    description:
      'Escuche muchos decir que la IA nos va a quitar empleo, sobre todo a las personas que trabajamos desde una computadora.Te cuento mi punto de vista en este Blog',
    date: 'Feb 19, 2024',
    datetime: '19-02-2024',
    category: { title: 'Programacion' },
    author: {
      name: 'Cristian Cabrera',
      role: 'CEO',
      imageUrl: imgCristian,
    },
  },
  {
    id: 2,
    title: '25 Preguntas de entrevista para programador Junior',
    description:
      'Al postularse, tenemos que pasar 2 procesos. El primero son preguntas técnicas del lenguaje que uno se postula. Fíjate cuáles son',
    date: 'Ago 03, 2024',
    datetime: '03-08-2024',
    category: { title: 'Pregunta Entrevista' },
    author: {
      name: 'Cristian Cabrera',
      role: 'CEO',
      imageUrl: imgCristian,
    },
  },
  {
    id: 3,
    title: '22 Preguntas Javascript para Semis Senior',
    description:
      'Preguntas para Semis Senior de Javascript',
    date: 'Oct 02, 2024',
    datetime: '02-10-2024',
    category: { title: 'Pregunta Entrevista' },
    author: {
      name: 'Cristian Cabrera',
      role: 'CEO',
      imageUrl: imgCristian,
    },
  },
  {
    id: 4,
    title: 'Consejos a la hora de diseñar paginas web',
    description:
        'Consejos a la hora de diseñar paginas web',
      date: 'Oct 10, 2024',
    datetime: '10-10-2024',
    category: { title: 'Consejos' },
    author: {
      name: 'Cristian Cabrera',
      role: 'CEO',
      imageUrl: imgCristian,
    },
  },
  // Más posts...
];

export default function Blog() {
  return (
    <div className="bg-gradient-to-r from-slate-500 to-slate-800 py-24 sm:py-32" id="Blog">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-6xl font-bold tracking-tight text-black sm:text-6xl font-bold">Mira nuestro Blog</h2>
          <p className="mt-2 text-lg font-bold leading-8 text-lime-400">
            Fíjate lo que opinan nuestros expertos sobre temáticas cotidianas
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-slate-700 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article 
              key={post.id} 
              className="flex max-w-xl flex-col items-start justify-between bg-white dark:bg-gray-800 shadow-lg rounded-lg p-5 transition-transform duration-300 ease-in-out hover:shadow-xl hover:-translate-y-2"
            >
              <div className="flex items-center gap-x-4 text-xs text-gray-500">
                <time dateTime={post.datetime} className="text-gray-500">
                  {post.date}
                </time>
                <a  
                  className="relative z-10 rounded-full bg-slate-200 text-slate-600 px-3 py-1.5 font-medium hover:bg-slate-300 dark:bg-gray-600 dark:text-gray-200 hover:dark:bg-gray-700">
                  {post.category.title}
                </a>
              </div>
              <div className="group relative mt-4">
                <h3 className="text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-700 dark:text-gray-100 dark:group-hover:text-gray-300">
                  <Link 
                    target='_blank'
                    to={getPostLink(post)}  
                  >
                    <span className="absolute inset-0" />
                    {post.title}
                  </Link>
                </h3>
                <p className="mt-3 line-clamp-3 text-sm leading-6 text-gray-600 dark:text-gray-400">{post.description}</p>
              </div>
              <div className="relative mt-8 flex items-center gap-x-4">
                <img src={post.author.imageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-100 dark:bg-gray-700" />
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900 dark:text-gray-100">
                    <a 
                      target='_blank'
                    >
                      <span className="absolute inset-0" />
                      {post.author.name}
                    </a>
                  </p>
                  <p className="text-gray-600 dark:text-gray-400">{post.author.role}</p>
                </div>
              </div>
              
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
