import React from 'react';
import { faqs } from './faqData';
import FaqItemChildren from './faqItemChildren';

const FaqEntreviewJunior = () => {
  return (
      <div className="bg-white p-4 rounded-lg shadow-xl py-8 mt-12">
        <h4 className="text-4xl font-bold text-gray-800 tracking-widest uppercase text-center">25 preguntas entrevistas para juniors</h4>
        <p className="text-center text-gray-600 text-sm mt-2">Estas son las preguntas tipicas a los programadores para trabajar en empresas</p>
        <div className="space-y-12 px-2 xl:px-16 mt-12">
          {faqs.map(({question, answer}, index) => (
            <FaqItemChildren key={index} question={question} answer={answer} />
          ))}
        </div>
      </div>
  );
};

export default FaqEntreviewJunior;
