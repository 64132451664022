import React from 'react';

export default function TimelineWithIcon() {
  return (
    <section>
      <div className="bg-gradient-to-r from-slate-500 to-slate-800 text-white py-8">
        <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
          <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8">
            <p className="ml-2 text-yellow-300 uppercase tracking-loose">Mi Progeso</p>
            <p className="text-3xl md:text-4xl leading-normal md:leading-relaxed mb-2">Trabajando dia a dia </p>
            <p className="text-sm md:text-base text-gray-50 mb-4">
              Esta fue una guia de mi vida en la programacion, como una simple idea de ayudar a personas a crecer en este mundo y sean mas libres, han tenido un impacto enorme en mi vida.
            </p>
            
          </div>
          <div className="ml-0 md:ml-12 lg:w-2/3 sticky">
            <div className="container mx-auto w-full h-full">
              <div className="relative wrap overflow-hidden p-10 h-full">
                <div className="border-2-2 border-yellow-555 absolute h-full border"
                  style={{right: '50%', border: '2px solid #FFC100', borderRadius: '1%'}}></div>
                <div className="border-2-2 border-yellow-555 absolute h-full border"
                  style={{left: '50%', border: '2px solid #FFC100', borderRadius: '1%'}}></div>
                
                {/* Timeline items */}
                <TimelineItem
                  date="13 Diciembre, 2023"
                  title="Lanzamiento de la plataforma"
                  description="Después de meses de planificación y trabajo duro, lancé mi plataforma con el objetivo de compartir conocimiento a través de blogs, cursos y videos. Aquí podrás encontrar contenido sobre programación, desarrollo web, y proyectos interactivos que he creado. Mi misión es ayudar a otros creadores y desarrolladores a mejorar sus habilidades y mantenerse al día con las últimas tecnologías."
                  isLeft={true}
                />
                <TimelineItem
                  date="23 Mayo, 2024"
                  title="Redes Sociales"
                  description="Comencé a crear contenido en redes sociales para llegar a más personas y compartir mi conocimiento. Aquí podrás encontrar tutoriales, consejos y actualizaciones sobre lo que hago."
                  isLeft={false}
                />
                <TimelineItem
                  date="13 Jul, 2024"
                  title="Reconocimiento de Google"
                  description="Fue una gran sorpresa para mi poder ser reconocido por Google cumpliendo objectivos de insignias. Espero poder seguir creciendo y ayudando a la comunidad."
                  isLeft={true}
                />
                <TimelineItem
                  date="19 Sep, 2024"
                  title="Freelancer"
                  description="Comencé a trabajar como freelancer para ayudar a otras personas a crear sus propias plataformas y aplicaciones. Aquí podrás encontrar mis servicios y cómo puedo ayudarte a crear tu propia plataforma."
                  isLeft={false}
                />
              </div>
              <img className="mx-auto -mt-36 md:-mt-36" src="https://user-images.githubusercontent.com/54521023/116968861-ef21a000-acd2-11eb-95ac-a34b5b490265.png" alt="Timeline decoration" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function TimelineItem({ date, title, description, isLeft }) {
  return (
    <div className={`mb-8 flex justify-between items-center w-full ${isLeft ? 'flex-row-reverse left-timeline' : 'right-timeline'}`}>
      <div className="order-1 w-5/12"></div>
      <div className={`order-1 w-5/12 px-1 py-4 ${isLeft ? 'text-right' : 'text-left'}`}>
        <p className="mb-3 text-base text-yellow-300">{date}</p>
        <h4 className="mb-3 font-bold text-lg md:text-2xl">{title}</h4>
        <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
          {description}
        </p>
      </div>
    </div>
  );
}