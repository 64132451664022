import React from 'react';
import projectsPersonal from '../utils/projectsPersonal'
import imgProfile from '../assets/portfolioProyect/WhatsApp Image 2024-09-03 at 11.53.37.jpeg';
import TagManager from 'react-gtm-module';

export default function PersonalPortfolio() {
  const followBtnEventLayer = ( event, title) =>{
    TagManager.dataLayer({
      dataLayer: {
        event: event,
        title: title
      }
    });
  }

  const proyectBtnEventLayer = ( event, title) =>{
    TagManager.dataLayer({
      dataLayer: {
        event: event,
        title: title
      }
    });
  }

  
   return (
    <section className="bg-white dark:bg-gray-900">
      <div className="container px-6 py-10 mx-auto">
        <h1 className="text-2xl font-semibold text-center text-gray-800 lg:text-3xl dark:text-white">
          Proyectos que podés construir como <span class="text-blue-500">Programador Junior</span>
        </h1>

        <div>
        <div class="flex items-center justify-center p-5">
          <div class="grid md:grid-cols-2 grid-cols-1 items-center gap-10 md:px-10">
            <div>
              <h1 class="mb-2 text-3xl font-bold dark:text-white"><span class="text-yellow-500">Hola,</span> Soy Cristian</h1>
              <p class="mb-6 py-3 font-semibold dark:text-white">Te dejo proyectos que te pueden servir para mejorar la lógica en <span class="text-yellow-500 font-bold">programación. </span>
              Mi <span class="text-yellow-500 font-bold">objetivo</span> es poder ayudar a la <span class="text-yellow-500 font-bold">comunidad a crecer</span> y democratizar la educacion para que todas las personas puedan ser libres.
              Semana a semana subire proyectos nuevos!
              </p>.
              <div class="flex justify-center space-x-5">
              <a 
                onClick={followBtnEventLayer('followInstagram', 'btnInstagram')}
                href="https://www.instagram.com/educacioncristian/" 
                target="_blank" 
                rel="noopener noreferrer"
                class="flex w-full items-center justify-center gap-1 rounded-2xl bg-black p-5 py-3 font-semibold text-white hover:bg-gray-800"
              >
                Sígueme en instagram 
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke-width="1.5" 
                  stroke="currentColor" 
                  class="h-6 w-6"
                >
                  <path 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    d="M16.5 3.75h-9A5.25 5.25 0 002.25 9v9A5.25 5.25 0 007.75 23.25h9A5.25 5.25 0 0022.25 18V9A5.25 5.25 0 0016.5 3.75zM12 14.625a2.625 2.625 0 110-5.25 2.625 2.625 0 010 5.25zm3.75-5.25a.75.75 0 100-1.5.75.75 0 000 1.5zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" 
                  />
                </svg>
              </a>
              <a 
                href="https://www.youtube.com/@educacionCristian" 
                onClick={followBtnEventLayer('followYoutube', 'btnYoutube')}
                target="_blank" 
                rel="noopener noreferrer"
                class="flex w-full items-center justify-center gap-1 rounded-2xl bg-red-600 p-5 py-3 font-semibold text-white hover:bg-red-700"
              >
                Sígueme en youtube 
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke-width="1.5" 
                  stroke="currentColor" 
                  class="h-6 w-6"
                >
                  <path 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    d="M21.8 8.36c-.2-1.42-1.34-2.55-2.76-2.55H4.96c-1.42 0-2.56 1.13-2.76 2.55C2 9.78 2 12 2 12s0 2.22.2 3.64c.2 1.42 1.34 2.55 2.76 2.55h14.08c1.42 0 2.56-1.13 2.76-2.55C22 14.22 22 12 22 12s0-2.22-.2-3.64zM9 15.8V8.2l6 3.8-6 3.8z" 
                  />
                </svg>
              </a>

              </div>
            </div>
            <div>
              <img src={imgProfile} alt="Profesor Javascript" title="Profesor Javascript" style={{ width: '250px', height: '300px' }}/>
            </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-3">
          {projectsPersonal.map(({ image, title, subtitle, githubCode, liveDemo, youtubeLink, newProyect }, index) => (
            <div key={index} className="relative">
              {newProyect && (
                <span className="absolute top-2 right-2 bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">
                NUEVO
                </span>
              )}
              <img
                className="object-cover w-full rounded-lg h-96"
                src={image}
                alt={title}
                title={title}
              />
              <h2 className="mt-4 text-xl font-semibold text-gray-800 dark:text-white">
                {title}
              </h2>
              <p className="mt-2 text-lg tracking-wider text-blue-500 dark:text-blue-400">
                {subtitle}
              </p>
              <div className="flex mt-4">
                <a
                  onClick={proyectBtnEventLayer('projectGithub', title)}
                  href={githubCode}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center w-1/2 px-4 py-2 mr-2 text-sm font-medium text-white bg-gray-800 rounded-lg hover:bg-gray-700"
                >
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 0C5.372 0 0 5.373 0 12a12 12 0 008.207 11.384c.6.112.82-.26.82-.577v-2.03c-3.34.726-4.042-1.61-4.042-1.61-.546-1.384-1.333-1.753-1.333-1.753-1.09-.744.082-.728.082-.728 1.205.084 1.838 1.238 1.838 1.238 1.07 1.836 2.805 1.305 3.49.997.107-.775.42-1.305.763-1.605-2.665-.303-5.467-1.332-5.467-5.931 0-1.31.468-2.38 1.236-3.22-.123-.303-.536-1.522.117-3.176 0 0 1.01-.322 3.3 1.23a11.37 11.37 0 016.008 0c2.29-1.552 3.298-1.23 3.298-1.23.655 1.654.243 2.873.12 3.176.77.84 1.235 1.91 1.235 3.22 0 4.61-2.806 5.625-5.478 5.922.431.372.817 1.106.817 2.226v3.305c0 .319.217.693.824.576A12 12 0 0024 12c0-6.627-5.373-12-12-12z" />
                  </svg>
                  GitHub
                </a>
                {liveDemo && (
                  <a
                    onClick={proyectBtnEventLayer('projectLiveDemo', title)}
                    href={liveDemo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center w-1/2 px-4 py-2 ml-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-500"
                  >
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 0C5.372 0 0 5.373 0 12a12 12 0 008.207 11.384c.6.112.82-.26.82-.577v-2.03c-3.34.726-4.042-1.61-4.042-1.61-.546-1.384-1.333-1.753-1.333-1.753-1.09-.744.082-.728.082-.728 1.205.084 1.838 1.238 1.838 1.238 1.07 1.836 2.805 1.305 3.49.997.107-.775.42-1.305.763-1.605-2.665-.303-5.467-1.332-5.467-5.931 0-1.31.468-2.38 1.236-3.22-.123-.303-.536-1.522.117-3.176 0 0 1.01-.322 3.3 1.23a11.37 11.37 0 016.008 0c2.29-1.552 3.298-1.23 3.298-1.23.655 1.654.243 2.873.12 3.176.77.84 1.235 1.91 1.235 3.22 0 4.61-2.806 5.625-5.478 5.922.431.372.817 1.106.817 2.226v3.305c0 .319.217.693.824.576A12 12 0 0024 12c0-6.627-5.373-12-12-12z" />
                    </svg>
                    Live Demo
                  </a>
                )}
                {youtubeLink && (
                  <a
                    href={youtubeLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center w-1/2 px-4 py-2 ml-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-500"
                  >
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19.615 3.184C18.42 2.723 12 2.723 12 2.723s-6.42 0-7.615.461C3.615 3.618 3 5.354 3 5.354s-.615 2.36-.615 4.723v2.846c0 2.363.615 4.723.615 4.723s.615 1.736 1.385 2.17c1.195.461 7.615.461 7.615.461s6.42 0 7.615-.461c.77-.434 1.385-2.17 1.385-2.17s.615-2.36.615-4.723V10.08c0-2.363-.615-4.723-.615-4.723s-.615-1.736-1.385-2.173zM9.545 15.444V8.59l5.545 3.427-5.545 3.427z" />
                    </svg>
                    Youtube
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      
    </section>
  );
}
