// src/data/portfolioProjects.js
import captchaGenerator from '../assets/portfolioProyect/captchaGenerator.jpeg';
import regaloImg from '../assets/portfolioProyect/regaloImg.png';
import generadorWIFI from '../assets/portfolioProyect/generadorWIFI.png';
import noteApp from '../assets/portfolioProyect/noteApp.png';
import tictactoe from '../assets/portfolioProyect/tic-tac-toe.png';
import convertCurrency from '../assets/portfolioProyect/convertCurrency.png';
import imgQuizzApp from '../assets/portfolioProyect/quizzApp.png';
import musicPlayer from '../assets/portfolioProyect/musicPlayer.png';
import bookLibrary from '../assets/portfolioProyect/bookLibrary.png';
import captureScreen from '../assets/portfolioProyect/captureScreen.png';
import jsonParse from '../assets/portfolioProyect/jsonParse.png';
import chatbot from '../assets/portfolioProyect/chatbot.png';
import mernAuthAdvance from '../assets/portfolioProyect/mernAuthAdvance.png';
import stripe from '../assets/portfolioProyect/stripe.png';
import textencryptor from '../assets/portfolioProyect/textencryptor.png';

const projectsPersonal = [
  {
    image: textencryptor,
    title: 'Text Encryptor',
    subtitle: 'Html, Css, Javascript',
    githubCode: 'https://github.com/crcristian97/text-encryptor',
    liveDemo: 'https://text-encryptor-qvzy.vercel.app/',
    newProyect: true,
  },
  {
    image: stripe,
    title: 'Stripe Payment Gateway',
    subtitle: 'NextJs y Stripe',
    githubCode: 'https://github.com/crcristian97/price-seccion-stripe',
    liveDemo: 'https://price-seccion-stripe.vercel.app/pricing',
    newProyect: true,
  },
  {
    image: mernAuthAdvance,
    title: 'MERN Auth Advance',
    subtitle: 'React, Node, Express, MongoDB y JWT',
    githubCode: 'https://github.com/crcristian97/mern-auth-advance',
    liveDemo: 'https://mern-auth-advance.onrender.com',
    newProyect: true,
  },
  {
    image: chatbot,
    title: 'Chat Bot App',
    subtitle: 'Html, Css , Javascript y Python',
    githubCode: 'https://github.com/crcristian97/chat-bot',
    liveDemo: 'https://chat-bot-0leu.onrender.com/',
    newProyect: true,
  },
  {
    image: jsonParse,
    title: 'JSON Parse App',
    subtitle: 'Html, Css y Javascript',
    githubCode: 'https://github.com/crcristian97/jsonParse',
    liveDemo: 'https://json-parse-navy.vercel.app/',
    newProyect: false,
  },
  {
    image: captureScreen,
    title: 'Capture Screen Shot',
    subtitle: 'Html, Css y Javascript',
    githubCode: 'https://github.com/crcristian97/captureScreen',
    liveDemo: 'https://capture-screen.vercel.app/',
    newProyect: false,
  },
  {
    image: bookLibrary,
    title: 'Book Library',
    subtitle: 'Html, Css y Javascript',
    githubCode: 'https://github.com/crcristian97/savebook',
    liveDemo: 'https://savebook.vercel.app/',
    newProyect: false,
  },
  {
    image: musicPlayer,
    title: 'Music Player',
    subtitle: 'Html, Css y Javascript',
    githubCode: 'https://github.com/crcristian97/music-player',
    liveDemo: 'https://music-player-wheat-beta.vercel.app/',
    newProyect: false,
  },
  {
    image: imgQuizzApp,
    title: 'Quizz App',
    subtitle: 'Html, Css y Javascript',
    githubCode: 'https://github.com/crcristian97/quizz-app-js',
    liveDemo: 'https://quizz-app-js-blue.vercel.app/',
    newProyect: false,
  },
  {
    image: convertCurrency,
    title: 'Convertidor de Moneda',
    subtitle: 'Html, Css y Javascript',
    githubCode: 'https://github.com/crcristian97/currency-convert',
    liveDemo: 'https://currency-convert-pearl.vercel.app/',
    newProyect: false,
  },
  {
    image: tictactoe,
    title: 'Tic Tac Toe',
    subtitle: 'Html, Css, Javascript',
    githubCode: 'https://github.com/crcristian97/tic-tac-toe/tree/main',
    liveDemo: 'https://tic-tac-toe-silk-theta-95.vercel.app/',
    newProyect: false,
  },
  {
    image: generadorWIFI,
    title: 'Generador QR de wifi',
    subtitle: 'Html, Css, Javascript y Python',
    githubCode: 'https://github.com/crcristian97/CodigoQR-Wifi.git',
    youtubeLink: 'https://www.youtube.com/watch?v=Xh9Aibq1mA8',
    newProyect: false,
  },
  {
    image: captchaGenerator,
    title: 'Captcha Generator',
    subtitle: 'Html, Css y Javascript',
    githubCode: 'https://github.com/crcristian97/captchaGenerator',
    liveDemo: 'https://captcha-generator-eta.vercel.app/',
    youtubeLink: 'https://www.youtube.com/watch?v=Po6ALDm45M0',
  },
  {
    image: regaloImg,
    title: 'Regalo de tiempo para tu pareja',
    subtitle: 'Html, Css y Javascript',
    githubCode: 'https://github.com/crcristian97/gif',
    liveDemo: 'https://gif-wine-phi.vercel.app/',
    newProyect: false,
  },
  {
    image: noteApp,
    title: 'Note Taking App',
    subtitle: 'Html, Css, Javascript',
    githubCode: 'https://github.com/crcristian97/noteApp.git',
    liveDemo: 'https://note-app-pi-neon.vercel.app/',
    newProyect: false,
  },
];

export default projectsPersonal;
