import React from 'react';
import PropTypes from 'prop-types';

const FaqItemChildren = ({ question, answer }) => (
  <div className="mt-4 flex">
    <div>
      <div className="flex items-center h-16 border-l-4 border-blue-600">
        <span className="text-4xl text-blue-600 px-4">Q.</span>
      </div>
      <div className="flex items-center h-16 border-l-4 border-gray-400">
        <span className="text-4xl text-gray-400 px-4">A.</span>
      </div>
    </div>
    <div>
      <div className="flex items-center h-16">
        <span className="text-lg text-blue-600 font-bold">{question}</span>
      </div>
      <div className="flex items-center py-2">
        <span className="text-gray-500">{answer}</span>
      </div>
    </div>
  </div>
);

FaqItemChildren.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default FaqItemChildren;
